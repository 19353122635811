<template>
    <div class="card card-top" >
        <div class="card-body ">
            <div class=" text-center ">
              <div class=" logo-header h-100">
                <img
                  src="../../../assets/img/shapes/waves-white.svg"
                  class="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                  alt="waves"
                />
                <div
                  class="position-relative d-flex align-items-center justify-content-center h-100"
                >
                  <img
                    class="w-100 position-relative z-index-2 "
                    src="../../../assets/img/reject.png"
                    alt="rocket"
                  />
                </div>
              </div>
              
            </div>

            <div class="row pt-4 text-center align-items-center justify-content-center">
              <h4 class="header-text"> Отменено </h4>
              <p class="text-description-start mt-2 ">
               Мы не смогли получить подтверждение о проведении вашей транзакции. 
              </p>
              <p class="text-description-start mt-2 ">
                Если вы оплатили и деньги списались с вашего счета - приготовьте чек и напишите в поддержку.
              </p>
            </div>
            
        </div>
      </div>
  
    <!-- <div class='row fixed-btn'>
      <button
        class="btn bg-light text-dark"
        @click="need_help()"
        type="button"
        >
          <i class="fas fa-user " aria-hidden="true"></i>
        
          <span class="p-2">Помощь</span>  
        </button
      >
      <button
        class="btn bg-dark text-light strong"
        @click="finish()"
        type="button"
        >
          <span class="p-2 strong">Вернуться в личный кабинет</span>  
        </button
      >

    </div> -->
</template>

<style scoped>
.my-btn-dark{
  background: #17191E;
  border-radius: 8px;
}
.step-indicator{
  background-color: #E2E2E2;
  width: 9px;
  height: 9px;
  border-radius:50%;
  padding:0px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.active-indicator{
  background-color: black ;
  width: 28px;
  border-radius: 20px;
}
.card-top {
  border-radius: 32px 32px 0 0;
  bottom:0;
  height:90%;
}
.logo-header{
   width: 60%;
   height: auto;
   margin-left: auto;
   margin-right: auto;
   margin-top:35px;
   margin-bottom:40px;
}
.text-description-start{
  max-width:80%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #403F3F;
}
.fixed-btn{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    margin: 0px;
}
.strong{
  font-weight: 900 !important;
  color: #fff !important;
}
.header-text{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    text-align: center;

    color: #1B1D21;
}
button{
  min-height:50px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
}

</style>
<script>

import { mapMutations,mapActions } from "vuex";

export default {
  name: "s1",
  components: {
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
  },
  beforeUnmount() {
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    next(){
        this.$store.state.step+=1
    },
    finish(){
      location.href = 'https://monetndvor.com/dashboard'
    },
    need_help(){
      location.href = `tg://resolve?domain=help_Mint_support?uid=${this.$store.state.uid}`
    },

  },
};
</script>
