<template>
  <div style='max-width:500px; margin:auto'>
      
    <div class="row p-4 ">
      <h5 class="header-text"> Правила использования </h5>
      <span class="episod mt-3">
        Стоимость транзакции
      </span>
      <p class="paragraph mt-2 ">
        Пожалуйста, перед переходом к оплате  просмотрите видео и ознакомьтесь с правилами использования p2p Mint, во избежании негативного опыта.
      </p>
      <span class="episod mt-2">
        Длительность транзакции
      </span>
      <p class="paragraph mt-2 ">
        После того как вы перейдете к оплате вам будет дано 15 минут на совершение перевода по выданым реквизитам. Это делается в целях безопасности и избежания потери денег. 
      </p>
      <span class="episod mt-2">
        Безопасность сделки
      </span>
      <p class="paragraph mt-2 ">
        Совершаемая вами сделка полностью безопасна! После подтверждения проведенного вами платежа средства будут зачислены в ваш личный кабинет в течении <strong>15 минут</strong>. Не забудьте по завершению нажать кнопку "Оплатил"
      </p>
    </div>

    <div class='row p-4 fixed-btn w-100'>
     
      <div class="col-12">
        <button
          class="black_btn_polina w-100"
          @click="next"
          type="button"
          >Принять</button
        >
      </div>
      

    </div>
    </div>
</template>

<style scoped>
.bg-my-light{
  background: #FAFAFB;
  border-radius: 8px;
  color: #17191E;
  box-shadow: none;

}
.bg-my-info{
  background: radial-gradient(103.03% 103.03% at 0% 0%, #414141 0%, #000000 100%);
  border-radius: 8px;
  color: #FFFFFF;
  box-shadow: none;

}

.paragraph{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.episod{

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  color: #000000;
}
.fixed-btn{
    bottom: 0;
    position: absolute;
    width: 100%;
    max-width:500px;
    
}
.header-text{
  font-family: 'FactorA-Regular-narrow';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 111% */

  color: #17191E;
}

</style>
<script>

import { mapMutations,mapActions } from "vuex";

export default {
  name: "s1",
  components: {
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
  },
  beforeUnmount() {
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    next(){
        this.$store.state.step+=1
    },
    regect(){
        location.href = 'https://monetndvor.com/dashboard'
    }

  },
};
</script>
