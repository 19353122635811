<template>
  <div class="card card-top" >
        <!-- <iframe class="ifeame-help" src="https://www.youtube.com/embed/fsHXEx1IcQk"></iframe> -->
        <div style="min-height:350px;"></div>
    </div>
      
    <div class="row pt-3 position-relative d-flex align-items-center justify-content-center ">
        <span class="step-indicator " @click="prev" style="cursor:pointer"></span>
        <span class="step-indicator active-indicator"></span>
    </div>
    <div class="row pt-4 text-center align-items-center justify-content-center">
      <h5 class="header-text"> Как это работает </h5>
      <p class="text-description-start mt-2 ">
        Пожалуйста, перед переходом к оплате  просмотрите видео и ознакомьтесь с правилами использования p2p Mint, во избежании негативного опыта. Деньги будут зачислены через 15 минут после нажатие кноопки "Оплатил"
      </p>
      
    </div>
    <div class='row mt-3 fixed-btn'>
      <button
        class="black_btn_polina"
        @click="next"
        type="button"
        >Далее</button
      >

    </div>
</template>

<style scoped>
.step-indicator{
  background-color: #E2E2E2;
  width: 9px;
  height: 9px;
  border-radius:50%;
  padding:0px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}
.ifeame-help{
  border-radius: 0 0 20px 20px;
   min-height:40vh;
}
.active-indicator{
  background-color: black ;
  width: 28px;
  border-radius: 20px;
}
.card-top {
  background-size:contain;
  border-radius: 0 0 32px 32px;
  background-image: radial-gradient(103.03% 103.03% at 0% 0%, #414141 0%, #000000 100%);
  /* background-image: url('../../../assets/img/andr.jpeg'); */
}
button{
  min-height:50px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
}
.logo-header{
   width: 30%;
   height: auto;
   margin-left: auto;
   margin-right: auto;
   margin-top:75px;
   margin-bottom:70px;
}
.text-description-start{
  max-width:77%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 143% */
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}
.fixed-btn{
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px;
    margin: 0px;
}
.header-text{
  font-family: 'FactorA-Regular-narrow';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 46px;
  /* identical to box height, or 111% */

  text-align: center;
  letter-spacing: -0.5px;

  color: #000000;
}

</style>
<script>

import { mapMutations,mapActions } from "vuex";

export default {
  name: "s1",
  components: {
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
  },
  beforeUnmount() {
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    next(){
        this.$store.state.step+=1
    },
    prev(){
        this.$store.state.step-=1
    },
    open_video(){
      window.open('https://docs.app.l1st.online/guide/p2p.html','_blank');
    }

  },
};
</script>
