<template>

   <!-- lav-greetings -->
   <section class="lav-greetings ">
    <header class="lav-greetings_header">
       <img class="lav-greetings_header-img" src="../../../assets/img/logo-greetings.svg" alt="логотип">
       <h1 class="lav-greetings_header-title text-light">Mint</h1>
    </header>
    <div class="lav-greetings-wrapper">
       <div class="lav-greetings-title">Приветствуем! Вы выбрали оплату через Mint P2P</div>
       <div class="lav-greetings_block">
          <p class="lav-greetings_block-title">Стоимость транзакции</p>
          <p class="lav-greetings_block-text">Стоимость транзакции составляет от 0,8% до 1,5% от суммы платежа</p>
       </div>
       <div class="lav-greetings_block">
          <p class="lav-greetings_block-title">Длительность транзакции</p>
          <p class="lav-greetings_block-text">После того как вы перейдете к оплате вам будет дано 15 минут на
             совершение переводы по выданным рекизитам. Это делается в целях безопасности и избежания потери денег.
          </p>
       </div>
       <div class="lav-greetings_block">
          <p class="lav-greetings_block-title">Безопасность сделки</p>
          <p class="lav-greetings_block-text">Совершаемая вами сделка полностью безопасна! После подтверждения
             проведенного вами платежа средства будут зачислены в ваш личный кабинет в течении й5 минут. Не забудьте
             по завершению нажать кнопку “Оплатил”</p>
       </div>
       <div @click="next" class="cursor-pointer lav-replenishment-btn">Пополнить</div>
    </div>
 </section> <!-- lav-greetings END -->

  <!-- <div class="card card-top" >
    <div class="card-body ">
        <div class=" text-center ">
          <div class=" logo-header h-50">
            <div
              class="position-relative d-flex align-items-center justify-content-center h-50"
            >
              <img
                class="w-50 position-relative "
                src="../../../assets/img/logologo.png"
                alt="rocket"
              />
            </div>
          </div>
        </div>
    </div>
  </div>
      
    <div class="row pt-3 position-relative d-flex align-items-center justify-content-center ">
        <span class="step-indicator active-indicator"></span>
        <span class="step-indicator"></span>
    </div>
    <div class="row ms-2 p-3">
      <span class=" mt-3" style="color:#000; font-weight:700; font-size:20px">
        Приветствуем! Вы выбрали оплату через Mint P2P
      </span>
     
      <span class=" mt-3" style="color:#000; font-weight:700;">
        Длительность транзакции
      </span>
      <p class=" mt-2 " style="color:#000; font-weight:400;">
        После того как вы перейдете к оплате вам будет дано 15 минут на совершение перевода по выданым реквизитам. Это делается в целях безопасности и избежания потери денег. 
      </p>
      <span class="episod mt-2" style="color:#000; font-weight:700;">
        Безопасность сделки
      </span>
      <p class=" mt-2 " style="color:#000; font-weight:400;">
        Совершаемая вами сделка полностью безопасна! После подтверждения проведенного вами платежа средства будут зачислены в ваш личный кабинет в течении <strong>15 минут</strong>. Не забудьте по завершению <strong>нажать кнопку "Оплатил"</strong>
      </p>
    </div>
    <div class='row mt-3 fixed-btn'>
      <button
        class="black_btn_polina"
        @click="next"
        type="button"
        >Далее</button
      >

    </div> -->
</template>

<script>

import { mapMutations,mapActions } from "vuex";

export default {
  name: "s1",
  components: {
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
  },

  unmounted() {
      this.root.style.setProperty("--bs-body-bg", "#fff");
    },
  mounted() {
      this.root = document.documentElement;
      this.root.style.setProperty("--bs-body-bg", "#0C0C0C");
    },


  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    next(){
        this.$store.state.step+=3
    }

  },
};
</script>


<style scoped>
  @import '../../../assets/styles/main.css';
</style>
